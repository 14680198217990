// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Player } from './pleyer';
import { Video } from './video';
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { Text } from './ui/text';
import { IMG } from './ui/img';
import { API_URL } from '../var';
import { StoreContext } from '../store/store';
import { Button } from './ui/button';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  flex-direction: column;
  margin: ${({ open }) => (open ? '2rem 0 0 2rem' : '2rem 0 0 0')};
  padding: 2.5rem;
  flex: 1;
  direction: ltr;
  background-color: ${({ theme }) => theme.lessonbody};

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

const Div = styled.div`
  margin: 3rem 0 0 0;
  display: flex;
  flex-direction: ${({ right }) => (right ? 'row-reverse' : 'row')};
  justify-content: space-between;
`;

export const LessonBody = ({ open }) => {
  const [{ lessons, lessonId }, dispatch] = useContext(StoreContext);
  const lesson = lessons[lessonId];
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    setConvertedContent(null);
    if (lesson?.text) {
      let currentContentAsHTML = convertToHTML(convertFromRaw(lesson.text));
      setConvertedContent(currentContentAsHTML);
    }
    window.scrollTo(0, 0);
  }, [lesson?.text]);

  // Функция для преобразования текстовых ссылок в HTML-ссылки
  const convertLinksToHTML = (text) => {
    // Проверяем, что text не null и не undefined
    if (text == null) {
      return ''; // Возвращаем пустую строку, если text равен null или undefined
    }

    // Очищаем текст от лишних HTML-тегов
    const cleanText = DOMPurify.sanitize(text);

    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return cleanText.replace(urlPattern, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
  };

  const createMarkup = (html) => {
    return {
      __html: convertLinksToHTML(html), // Применяем преобразование ссылок перед очисткой
    };
  };

  if (lesson) {
    const { video, image, audio, header } = lesson;
    return (
      <Wrapper open={open}>
        <Text type='h1'>{header}</Text>

        {image && <IMG src={`${API_URL}${image}`} alt="Изображение урока" />}
        {video && <Video video={video} width='100%' height='500px' />}
        {audio && <Player audio={`${API_URL}${audio}`} />}
        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
        <Div right={lessonId === 0}>
          {lessonId !== 0 && (
            <Button padding="1.25rem 1.7rem"
              onClick={() => dispatch({ type: "SET_LESSON_ID", payload: lessonId - 1 })}
            >
              Предыдущий
            </Button>
          )}
          {lessons.length - 1 !== lessonId && (
            <Button padding="1.25rem 1.7rem"
              onClick={() => dispatch({ type: "SET_LESSON_ID", payload: lessonId + 1 })}
            >
              Следующий
            </Button>
          )}
        </Div>
      </Wrapper>
    );
  } else return null;
};
