// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Button } from './ui/button';
import { Text } from './ui/text';
import { Input } from './ui/input';
import { addСourse, updateСourse, updateStatusCourse } from './net';
import { StoreContext } from '../store/store';

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    flex-direction: column;
    margin: 2rem 0 0 2rem;
    padding: 2.5rem;
    background-color: ${({ theme }) => theme.lessonbody};
    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Div = styled.div`
    display: flex;
    align-items: center;
`;

const Textarea = styled.textarea`
    color: ${({ theme }) => theme.text};
    font-family: 'Ubuntu';
    font-weight: 200;
    box-sizing: border-box;
    min-width: 300px;
    border: none;
    margin: 5px;
    background-color: ${({ theme }) => theme.input.bg};
    padding: 1em;
    &:focus {
        border: solid 0.5px ${({ theme }) => theme.body};
        background-color: ${({ theme }) => theme.input.hover};
    }
    &::placeholder {
        color: ${({ theme }) => theme.input.placeholder};
    }
`;

export const AddCourse = ({ auth, fetchCourse }) => {
    const [{ courses, courseIndex, selector }, dispatch] = useContext(StoreContext);

    const [fields, setFields] = useState({
        name: "",
        header: "",
        description: "",
        idc: auth.idc,
        action: "add",
        paid: false,
        price: null,
        privat_chat: null,
        stripePrice: null,
        bot_link: null,
        inviteLink: null,
        published: false,
        seminar: false,
    });

    useEffect(() => {
        if (selector === "curedit") {
            const fildsFromCourse = courses[courseIndex];
            setFields(prevFields => ({ ...prevFields, ...fildsFromCourse }));
        }
    }, [selector, courses, courseIndex]);

    const sendData = async () => {
        try {
            if (selector === "curedit") {
                await updateСourse({
                    ...fields,
                    courseId: fields.id,
                    action: "update"
                });
                dispatch({ type: 'EDIT_COURSE', payload: fields });
            } else {
                const res = await addСourse({ ...fields, action: "add" });
                const cur = await fetchCourse({ idc: auth.idc });
                dispatch({ type: 'SET_COURSES', payload: cur });
                dispatch({ type: 'SET_COURSE_INDEX', payload: cur.length - 1 });
            }
            dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload: 'course' });
            dispatch({ type: 'SET_LESSONS', payload: [] });
        } catch (error) {
            console.log(error);
        }
    };

    const block = fields.name && fields.header && fields.description;

    const updateStatus = async (e) => {
        const published = e.target.checked;
        setFields(prevFields => ({ ...prevFields, published }));
        try {
            await updateStatusCourse({
                courseId: fields.id,
                published,
                idc: fields.idc,
                action: "status",
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Wrapper>
            <Text type="h1">Создать новый курс</Text>
            <Form>
                <Input placeholder="Имя курса" value={fields.name} type="text" onChange={e => setFields({ ...fields, name: e.target.value })} />
                <Input placeholder="Краткий хедер" value={fields.header} type="text" onChange={e => setFields({ ...fields, header: e.target.value })} />
                <Textarea rows="10" placeholder="Краткое описание" value={fields.description} onChange={e => setFields({ ...fields, description: e.target.value })} />
                <Input placeholder="Канал приватного чата" type="text" value={fields.privat_chat} onChange={e => setFields({ ...fields, privat_chat: e.target.value })} />
                <Input placeholder="Линк на изображение" type="text" value={fields.image} onChange={e => setFields({ ...fields, image: e.target.value })} />
                <Input placeholder="Линк бота" type="text" value={fields.bot_link} onChange={e => setFields({ ...fields, bot_link: e.target.value })} />
                <Div>
                    <Input placeholder="Семинар или курс" id="seminar" type="checkbox" checked={fields.seminar} onChange={e => setFields({ ...fields, seminar: e.target.checked })} />
                    <Text margin="0 20px">{fields.seminar ? "Семинар" : "Курс"}</Text>
                </Div>
                <Div>
                    <Input placeholder="Платный" id="paid" type="checkbox" checked={fields.paid} onChange={e => setFields({ ...fields, paid: e.target.checked })} />
                    <Text margin="0 20px">Курс: {fields.paid ? "Платный" : "Бесплатный"}</Text>
                </Div>
                {fields.paid && <Input placeholder="Стоимость" value={fields.price} type="number" onChange={e => setFields({ ...fields, price: e.target.value })} />}
                {fields.paid && <Input placeholder="Страйп идентификатор price_id" value={fields.stripePrice} type="text" onChange={e => setFields({ ...fields, stripePrice: e.target.value })} />}
                {fields.paid && <Input placeholder="Добавить инвайт линк" type="text" value={fields.inviteLink} onChange={e => setFields({ ...fields, inviteLink: e.target.value })} />}
            </Form>

            <Button width="300px" size="1em" text="Сохранить" disabled={!block} onClick={sendData} />
            <Button onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload: 'course' })} size="1em" text="Отменить" width="300px" />
            <Div>
                <Input placeholder="Опубликовать" type="checkbox" id="status" checked={fields.published} onChange={updateStatus} />
                <Text margin="0 20px">{fields.published ? "Опубликовано" : "Видит только Админ"}</Text>
            </Div>
        </Wrapper>
    );
};
